import React from "react";
import ctl from "@netlify/classnames-template-literals";
import { Container, ReadMore, Text } from "components";

const ServicesInfo = ({ reverse, image, heading, paragraph }) => {
  const id = heading.replaceAll(" ", "-").toLowerCase();
  return (
    <Container>
      <section className={`${sectionWrapStyle} `} id={id}>
        <div className={imageWrapStyle}>{image}</div>

        <div className={`${textWrapStyle} ${reverse && "lg:-order-1"}`}>
          <Text variant="h4" value={heading} color="primary" />

          <Text variant="p18" value={paragraph} className={paragraphStyle} />

          <ReadMore
            text="Register Now"
            to="/register#form"
            color="secondary-700"
          />
        </div>
      </section>
    </Container>
  );
};

const sectionWrapStyle = ctl(`
  bg-primary-100
  2xl:my-[214px]
  lg:my-[140px]
  my-24
  xl:pl-[59px]
  pl-[22px]
  flex
  flex-col lg:flex-row
  lg:gap-[76px]
  md:gap-10
  lg:items-center
`);
const imageWrapStyle = ctl(`
  sm:w-[456px] 
  w-[289px] 
  -mt-[55px]
`);
const textWrapStyle = ctl(`
  pt-[17px] 
  pb-10 
  xl:py-0
`);
const paragraphStyle = ctl(`
  lg:max-w-[513px] 
  sm:max-w-[450px]
  max-w-[341px]
  py-[17px]
`);

export { ServicesInfo };
