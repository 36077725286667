import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { ServicesInfo } from "../services-info/services-info";

const PublicSpeakingService = () => {
  return (
    <ServicesInfo
      image={
        <StaticImage
          src="../../../assets/images/pngs/services/public-speaking.webp"
          alt="service image"
        />
      }
      heading="Public Speaking"
      paragraph="We love to inspire teams to take charge of their safety needs. Whether you are looking to inspire your teams, or take your team on a safety odyssey or a ride on a sustainability journey. Speak with our team and we will make it  happen! "
    />
  );
};

export { PublicSpeakingService };
