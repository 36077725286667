import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { ServicesInfo } from "../services-info/services-info";

const ResearchService = () => {
  return (
    <ServicesInfo
      reverse
      image={
        <StaticImage
          src="../../../assets/images/pngs/services/research-and-application.webp"
          alt="service image"
        />
      }
      heading="Research & Application"
      paragraph="We are proud to offer our expertise, deftly combining coursework, research, and extensive experience. From advanced leadership skills to specialized HSE knowledge, Wetse HSE practitioners help groom professionals to unlock value for individual businesses."
    />
  );
};

export { ResearchService };
