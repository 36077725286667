import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { ServicesInfo } from "../services-info/services-info";

const ConsultationService = () => {
  return (
    <ServicesInfo
      image={
        <StaticImage
          src="../../../assets/images/pngs/services/professional-consultation.webp"
          alt="service image"
        />
      }
      heading="Professional Consultation"
      paragraph="We help businesses adopt Safety, health, environment and risk management practices by providing innovative solutions to challenges faced by workplaces. We are conversant with the requirements of industry and therefore our services are current and relevant. Through teamwork, training, consultation and management we have been performing services in a wide range of environments for years."
    />
  );
};

export { ConsultationService };
