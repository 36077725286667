import React from "react";
import { Kickstart, Layout } from "components";
import {
  OurServicesPageHeader,
  PodcastService,
  WebinarService,
  CareerCoachingService,
  ResearchService,
  ConsultationService,
  BespokeService,
  PublicSpeakingService,
} from "templates/our-services";

const OurServices = () => {
  return (
    <Layout title="Our Services">
      <OurServicesPageHeader />
      <WebinarService />
      <PodcastService />
      <CareerCoachingService />
      <ResearchService />
      <ConsultationService />
      <BespokeService />
      <PublicSpeakingService />
      <Kickstart />
    </Layout>
  );
};

export default OurServices;
