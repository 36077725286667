import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { ServicesInfo } from "../services-info/services-info";

const PodcastService = () => {
  return (
    <ServicesInfo
      reverse
      image={
        <StaticImage
          src="../../../assets/images/pngs/services/educational-podcast-sherm.png"
          alt="service image"
        />
      }
      heading="Educational Podcast"
      paragraph="We host an educational podcast centered around the Safety, health, environment, and risk management industry. It's home to insightful topics that' ll make your life, career, and that of your employees easier, and more productive. New episodes are published each week. "
    />
  );
};

export { PodcastService };
