import React from "react";
import { PageHeaderTwo } from "components";
import { StaticImage } from "gatsby-plugin-image";

const OurServicesPageHeader = () => {
  return (
    <PageHeaderTwo
      title="Stay Inspired!"
      description={{
        text: "Address Health, Safety, Environment and Risk Management Issues in Your Profession and Workplace.",
        width: "554px",
      }}
      readMore={{ text: "Discover new insights", to: "/" }}
      image={
        <StaticImage
          alt="Service man"
          src="../../../assets/images/pngs/services/Header.png"
          layout="fullWidth"
        />
      }
    />
  );
};

export { OurServicesPageHeader };
