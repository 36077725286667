import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { ServicesInfo } from "../services-info/services-info";

const BespokeService = () => {
  return (
    <ServicesInfo
      reverse
      image={
        <StaticImage
          src="../../../assets/images/pngs/services/bespoke-trainings-and-seminars.webp"
          alt="service image"
        />
      }
      heading="Bespoke Trainings & Seminars "
      paragraph="Our sessions take participants from the classroom into the wilds to apply these ideas to their projects. Using learning techniques of the most advanced universities, our trainers are qualified educators; passionate about quality training delivery. We deliver seminars on health, safety, environment, risk management and global sustainability-related topics."
    />
  );
};

export { BespokeService };
