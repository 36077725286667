import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { ServicesInfo } from "../services-info/services-info";

const CareerCoachingService = () => {
  return (
    <ServicesInfo
      image={
        <StaticImage
          src="../../../assets/images/pngs/services/career-coaching-and-mentoring.webp"
          alt="service image"
        />
      }
      heading="Career Coaching & Mentoring"
      paragraph="We utilize strategy to accelerate the career of top performing individuals. We are able to provide exceptional individuals with a faster route to the top of their respective industries so they can attain their highest potential."
    />
  );
};

export { CareerCoachingService };
